var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[(_vm.card)?_c('v-card',{staticClass:"hover-button d-flex justify-center align-center hover-card",class:{
      [_vm.classes]: true,
      ..._vm.customButtonClasses,
    },style:({ ..._vm.customButtonStyles }),attrs:{"elevation":"0","disabled":_vm.disabled,"height":"8em","role":"button","width":"10em","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('click')},"mouseleave":function($event){_vm.hover = null},"mouseover":function($event){_vm.hover = true}}},[_vm._t("default")],2):_vm._e(),(!_vm.card)?_c('v-btn',{staticClass:"hover-button flex-shrink-1",class:{
      'px-2': !_vm.small,
      'px-2': _vm.small,

      [_vm.classes]: true,
      ..._vm.customButtonClasses,
    },style:({
      width: `${_vm.width}`,
      height: `${_vm.height}`,
      ..._vm.customButtonStyles,
    }),attrs:{"depressed":"","disabled":_vm.disabled,"loading":_vm.loading},on:{"click":function($event){return _vm.$emit('click')},"mouseleave":function($event){return _vm.toggleHover(false)},"mouseover":function($event){return _vm.toggleHover(true)}}},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
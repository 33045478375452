<template>
  <register-container>
    <template slot="header">{{ pageTitle || 'Are you a new or returning patient?' }}</template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <div class="choice-container d-flex justify-space-around mt-6">
      <div>
        <hover-button :disabled="loading" @click="goNext(false)">New</hover-button>
      </div>
      <div>
        <hover-button :disabled="loading" @click="goNext(true)">Returning</hover-button>
      </div>
    </div>
  </register-container>
</template>
<style scoped>
.choice-container {
  width: 100%;
}
</style>
<script>
import HoverButton from '../components/HoverButton.vue';
import RegisterContainer from '../components/RegisterContainer.vue';

export default {
  name: 'RegisterNewReturning',
  components: { HoverButton, RegisterContainer },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async goNext(isReturningPatient) {
      await this.$store.commit('setRegistration', { isReturningPatient });
      this.$emit('next');
    },
  },
};
</script>

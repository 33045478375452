<template>
  <v-container
    class="form-container px-0 px-sm-6 py-0"
    fluid
    no-gutters
    ref="registerContentWrapper"
    :class="{ 'd-flex justify-center align-center': centerContent }"
  >
    <div class="form-header d-flex flex-column align-center justify-center" v-if="$slots.header">
      <h1
        class="text-center text-xs-h3 text-sm-h3 font-weight-bold title-text"
        :class="{
          'text-no-wrap': this.$vuetify.breakpoint.xlAndUp,
        }"
      >
        <slot name="header"></slot>
      </h1>
      <h5 class="subtitle-text text-center mt-6 mb-2" v-if="$slots.subtitle">
        <slot name="subtitle"></slot>
      </h5>
    </div>

    <div
      class="form-content mx-4 my-0"
      :style="{
        height: fullHeight && this.$vuetify.breakpoint.smAndUp ? '100%' : 'unset',
        marginBottom: pharmacy ? '74px' : '16px',
      }"
    >
      <slot></slot>
    </div>
  </v-container>
</template>
<style>
.form-container {
  width: 100%;
}
.subtitle-text {
  font-size: 0.9em;
  font-weight: normal;
  max-width: 700px;
}
.form-header {
  padding-top: 40px;
  padding: 24px;
}
@media all and (min-width: 1264px) {
  .form-container {
    max-width: 1000px;
  }
}
</style>
<script>
export default {
  name: 'RegisterContainer',
  data() {
    return {};
  },
  props: {
    centerContent: Boolean,
    fluid: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    pharmacy() {
      return !!this.$store.state.registration.patientData.pharmacy;
    },
  },
};
</script>

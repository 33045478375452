<template>
  <fragment>
    <v-card
      elevation="0"
      @click="$emit('click')"
      class="hover-button d-flex justify-center align-center hover-card"
      :class="{
        [classes]: true,
        ...customButtonClasses,
      }"
      :disabled="disabled"
      @mouseleave="hover = null"
      @mouseover="hover = true"
      height="8em"
      role="button"
      v-if="card"
      width="10em"
      :loading="loading"
      :style="{ ...customButtonStyles }"
    >
      <slot></slot>
    </v-card>
    <v-btn
      class="hover-button flex-shrink-1"
      :class="{
        'px-2': !small,
        'px-2': small,

        [classes]: true,
        ...customButtonClasses,
      }"
      :style="{
        width: `${width}`,
        height: `${height}`,
        ...customButtonStyles,
      }"
      @click="$emit('click')"
      depressed
      :disabled="disabled"
      @mouseleave="toggleHover(false)"
      @mouseover="toggleHover(true)"
      v-if="!card"
      :loading="loading"
    >
      <slot></slot>
    </v-btn>
  </fragment>
</template>

<style lang="scss">
.hover-button {
  font-size: 18px !important;
  .v-card__title {
    i {
      color: inherit !important;
    }
  }
  .v-btn__content {
    padding: 0px 10px !important;
  }
}
.hover-card {
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  min-width: unset !important;
  .v-card__title {
    word-break: keep-all;
    text-align: center;
  }
  &:focus {
    &:before {
      opacity: 0 !important;
    }
  }
}
.hover-card.v-card--disabled {
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>

<script>
import { buttonStyles } from '../constants/themes';

export default {
  name: 'HoverButton',
  props: [
    'card',
    'disabled',
    'selected',
    'small',
    'active',
    'block',
    'customHeight',
    'customWidth',
    'loading',
    'classes',
    'variant',
  ],
  data() {
    return {
      hover: null,
    };
  },
  computed: {
    customButtonClasses() {
      const defaultClasses = {
        'primary white--text font-weight-bold': (this.selected || this.active) && !this.disabled,
        'primary white--text': this.hover && !this.disabled,
      };
      const customClasses =
        (buttonStyles[this.$store.state.theme] &&
          buttonStyles[this.$store.state.theme].customButtonClasses &&
          buttonStyles[this.$store.state.theme].customButtonClasses(this)) ||
        {};
      return { ...defaultClasses, ...customClasses };
    },
    customButtonStyles() {
      const defaultStyles = {
        border: !this.hover && !this.selected && !this.disabled && '1px solid black',
        background: !this.hover && !this.selected && !this.disabled && 'white',
        'border-radius': '8px',
        color: !this.selected && !this.hover && 'black',
      };
      const customStyles =
        (buttonStyles[this.$store.state.theme] &&
          buttonStyles[this.$store.state.theme].customButtonStyles &&
          buttonStyles[this.$store.state.theme].customButtonStyles(this)) ||
        {};
      return { ...defaultStyles, ...customStyles };
    },
    height() {
      if (this.customHeight) {
        return this.customHeight;
      }
      if (this.card) {
        return '8em';
      }
      return '53px';
    },
    width() {
      if (this.customWidth) {
        return this.customWidth;
      }
      if (this.card) {
        return '10em';
      }
      if (this.block) {
        return '100%';
      }
      if (this.small) {
        return '';
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return '212px';
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return '160px';
      }
      return '150px';
    },
  },
  methods: {
    toggleHover(bool) {
      if (this.$vuetify.breakpoint.mdAndDown) return;
      this.hover = bool;
    },
  },
  watch: {
    disabled: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.hover = null;
      },
    },
  },
};
</script>
